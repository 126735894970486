<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'alerts',
		display: [
			'id',
			'alertTypeId',
			'clientRef',
			'userId',
			'attributes',
			'isResolved',
			'createdAt',
			'updatedAt'
		],
		canEdit: false
	}
}

export default {
	name: 'AlertsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
