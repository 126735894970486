<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'alerts',
		display: [
			{ key: 'id' },
			{ key: 'alertTypeId' },
			{ key: 'clientRef' },
			{ key: 'userId' },
			{ key: 'isResolved' },
			{ key: 'createdAt' }
		],
		modifier: async data =>
			data.map(r => {
				r.isResolved = r.isResolved ? '✅' : '❌'
				return r
			})
	}
}

export default {
	name: 'AlertsList',
	components: { ListPageGen },
	data: () => config
}
</script>
